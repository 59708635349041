import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  DotChartOutlined,
  BulbOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  GoldOutlined,
  ProjectOutlined,
  AuditOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  CalendarOutlined,
  
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard',
      icon: DashboardOutlined,
      authority : ['admin'], 
      breadcrumb: false,
      submenu: []
    },
   
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: [
   

    {
      key: 'projects',
      title: 'PROJECTS',
      icon: AppstoreOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'AddNewProject',
          path: `${APP_PREFIX_PATH}/pages/Addnewproject`,
          title: 'Add new project',
          icon: PlusCircleOutlined,
          breadcrumb: true,
        },
        // {
        //   key: 'Projects',
        //   path: `${APP_PREFIX_PATH}/pages/projects`,
        //   title: 'Projects',
        //   icon: AuditOutlined,
        //   breadcrumb: true,
        // },
        // {
        //   key: 'CompareProject',
        //   path: `${APP_PREFIX_PATH}/pages/compareProject`,
        //   title: 'Compare project',
        //   icon: DotChartOutlined,
        //   breadcrumb: true,
        // },
      ]
    },
    {
      key: 'IMMO',
      path: `${APP_PREFIX_PATH}/pages/immo`,
      title: 'IMMO',
      icon: FundOutlined,
      breadcrumb: true,
      submenu: []
    },

    {
      key: 'Checksum',
      path: `${APP_PREFIX_PATH}/pages/checksum`,
      title: 'Checksum',
      icon: SettingOutlined,
      breadcrumb: true,
      submenu: []
    },

    {
      key: 'Solutions',
      path: `${APP_PREFIX_PATH}/pages/solutions`,
      title: 'Solutions',
      icon: CarOutlined,
      breadcrumb: true,
      submenu: []
    },

    {
      key: 'Original Files',
      path: `${APP_PREFIX_PATH}/pages/original_files`,
      title: 'Original Files',
      icon: FileTextOutlined,
      breadcrumb: true,
      submenu: []
    },


    {
      key: 'ECU INFO',
      path: `${APP_PREFIX_PATH}/pages/ecuInfo`,
      title: 'ECU INFO',
      icon: FundOutlined,
      breadcrumb: true,
      submenu: []
    },

  ]
}]




const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
]

export default navigationConfig;
