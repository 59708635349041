import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
} from "../actions/Auth";
import {API_BASE_URL} from "./../../configs/AppConfig";
import axios from 'axios' ;
import FirebaseService from 'services/FirebaseService'

export function* signInWithFBEmail() {


  yield takeEvery(SIGNIN,  function* ({payload}) {
		const {email, password} = payload;

		try {
		   const res =  yield   axios.post(API_BASE_URL+"/users/login",{email , password});
				if (res.data.status === "success") {
					const user = res.data.data ;
					const token = res.data.token;
					localStorage.setItem(AUTH_TOKEN, token);
					yield put(authenticated(user,token));	
				} else {
					yield put(showAuthMessage(res.data.message));
			
				}
			 
		
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}




export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password , name , phone , adress , surname , passwordConfirm} = payload;
		try {
			const res = yield axios.post(API_BASE_URL+'/users/signup',{email, password , name , phone , adress , surname , passwordConfirm});
			if (res.data.status === 'failed') {
				yield put(showAuthMessage(res.data.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, res.data.token);
				yield put(signUpSuccess(res.data.token));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
