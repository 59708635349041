import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  DotChartOutlined,
  BulbOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  GoldOutlined,
  ProjectOutlined,
  AuditOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  CalendarOutlined,
  
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard',
      icon: DashboardOutlined,
      authority : ['admin'], 
      breadcrumb: false,
      submenu: []
    },
   
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: [
   
    // {
    //   key: 'Intervention',
    //   path: `${APP_PREFIX_PATH}/pages/interventions`,
    //   title: 'Intervention',
    //   icon: ProjectOutlined,
    //   breadcrumb: true,
    //   submenu: []
    // },

    // {
    //   key: 'Planing',
    //   path: `${APP_PREFIX_PATH}/apps/calendar`,
    //   title: 'Planing',
    //   icon: CalendarOutlined,
    //   breadcrumb: true,
    //   submenu: [],
    // },


    {
      key: 'Gears',
      path: `${APP_PREFIX_PATH}/pages/gears`,
      title: 'Gears',
      icon: SettingOutlined,
      breadcrumb: true,
      submenu: []
    },

        {
      key: 'Operation',
      path: `${APP_PREFIX_PATH}/pages/operations`,
      title: 'operation',
      icon: GoldOutlined,
      breadcrumb: true,
      submenu: []
    },


    // {
    //   key: 'invoices',
    //   path: `${APP_PREFIX_PATH}/apps/ecommerce`,
    //   title: 'Invoices',
    //   icon: AuditOutlined,
    //   breadcrumb: true,
    //   submenu: [
    //     {
    //       key: 'apps-ecommerce-productList',
    //       path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
    //       title: 'factures List',
    //       icon: '',
    //       breadcrumb: true,
    //       submenu: []
    //     },
    //     {
    //       key: 'extra-pages-invoice',
    //       path: `${APP_PREFIX_PATH}/pages/invoice`,
    //       title: 'sidenav.pages.invoice',
    //       icon: '',
    //       breadcrumb: true,
    //       submenu: []
    //     },
        
        
    //   ]
    // }
  ]
}]




const navigationConfig = [
  ...appsNavTree,
]

export default navigationConfig;
