import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  DotChartOutlined,
  BulbOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards/default`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  authority : ['admin'],
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard',
      icon: DashboardOutlined,
      authority : ['admin'], 
      breadcrumb: false,
      submenu: []
    }
 
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: [
   
    {
      key: 'apps-project-ClientList',
      path: `${APP_PREFIX_PATH}/pages/clients`,
      title: 'Clients',
      icon: UsergroupAddOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'apps-project-Staff',
      path: `${APP_PREFIX_PATH}/pages/staff`,
      title: 'Staff',
      icon: UserOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'offers',
      path: `${APP_PREFIX_PATH}/pages/offer-list`,
      title: 'Offers',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu : []
    },
    {
      key: 'invoices',
      path: `${APP_PREFIX_PATH}/apps/ecommerce`,
      title: 'Invoices',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'apps-ecommerce-productList',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
          title: 'factures List',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'extra-pages-invoice',
          path: `${APP_PREFIX_PATH}/pages/invoice`,
          title: 'sidenav.pages.invoice',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        
        
      ]
    }
  ]
}]




const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
]

export default navigationConfig;
